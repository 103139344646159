import { Utils } from "@/plugins/utils";
import axios from 'axios'

export default {
    namespaced: true,
    state: {
        filteredProducts: null,
        product: {},
        selectedGroups: [],
        selectedColors: [],
        selectedSizes: [],
        search: "",
        showFilters: false,
        loadingProducts: false,
        loadingProduct: false,
        selectedColor: null,
        selectedSize: null,
        sheet: false,
        vd3List:[],
    },
    getters: {
    },
    mutations: {
        addVd3(state, vd3){
            state.vd3List.push(vd3)
        },
        closeSheet(state){
            state.sheet = false
        },
        clearSelection(state){
            state.vd3List = []
            state.selectedSize = null
            state.selectedColor = null
        },
        clearFilteredFields(state){
            state.filteredProducts = []
            state.selectedGroups= []
            state.selectedColors= []
            state.selectedSizes= []
        }
    },
    actions: {
        async search(store, {search = "", groups = "", collections = "", colors = "", sizes = ""}) {
            store.state.loadingProducts = true
            await axios.get(`${process.env.VUE_APP_API_URI}/Product/Product/Search?search=${search}&groups=${groups}&collections=${collections}&colors=${colors}&sizes=${sizes}`,
            { 'Content-Type': 'application/json' })
            .then(response => {
                store.state.search = ""
                let filteredProducts = Utils.ValidateAxiosResponse(response.data);
                store.state.filteredProducts = filteredProducts
            })
            .catch((error) => {
                store.dispatch("parameters/openDialog", {
                    titleText: "Erro",
                    titleColor: "red",
                    text: error.message
                }, { root: true })
            });
            store.state.loadingProducts = false
        },
        async find(store, pr0produto){
            store.state.loadingProduct = true
            store.commit("clearSelection")
            await axios.get(`${process.env.VUE_APP_API_URI}/Product/Product/Find/${pr0produto}`, { 'Content-Type': 'application/json' })
            .then(response => {
                let productModel = Utils.ValidateAxiosResponse(response.data);
                store.state.product = productModel
                return true
            })
            .catch((error) => {
                store.dispatch("parameters/openDialog", {
                    titleText: "Erro",
                    titleColor: "red",
                    text: error.message
                }, { root: true })
                return false
            });
            store.state.loadingProduct = false
        },
        addVd3(store, vd3){
            const filterVd3 = item => item.vd2produto == vd3.vd2produto &&
                                      item.vd3opcao == vd3.vd3opcao &&
                                      item.vd3tamanho == vd3.vd3tamanho
               
            let vd3InCart = store.state.vd3List.filter(filterVd3)[0]
            if (vd3InCart) {
                vd3InCart.vd3qtde++
            } else {
                store.commit("addVd3", vd3)
            }
        },
        removeVd3(store, vd3){
            let index = store.state.vd3List.indexOf(vd3)
            store.state.vd3List.splice(index, 1)
        },
        chooseColor(store, pr2){
            if (pr2){
                store.state.product.pr2opcao = pr2.pr2opcao
                store.state.product.cr1nome = pr2.cr1nome
                return
            }
            store.state.product.pr2opcao = ""
        },
        chooseSize(store, pr3){
            if (pr3){
                store.state.product.pr3tamanho = pr3.pr3tamanho
                store.state.product.gr1desc = pr3.gr1desc
                store.state.product.pr0preco = pr3.pr3preco
                return
            }
            store.state.product.pr3tamanho = ""
        },
        closeSheet(store){
            store.commit("closeSheet")
        },
        clearSelection(store){
            store.commit("clearSelection")
        },
        clearFilteredFields(store){
            store.commit("clearFilteredFields")
        }
    }
}