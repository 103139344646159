<template>
    <v-main>
        <v-container class="pb-12">
            <v-row class="pt-5">
                <v-col cols="12" class="py-0">
                    <h2
                        :style="{ color: parameters.primaryColor }"
                    >
                        Recuperação de senha
                    </h2>
                </v-col>
                <v-col cols="12" class="d-flex justify-center">
                    <v-card elevation="0" outlined :loading="recoverPass.loading" class="py-1 px-2" :style="{borderColor: parameters.primaryColor}" height="auto" max-width="900px">
                        <template slot="progress">
                            <v-progress-linear
                                :color="parameters.primaryColor"
                                height="10"
                                indeterminate
                            ></v-progress-linear>
                        </template>
                        <v-card-subtitle>Digite os dados de acesso e a senha temporária enviada por e-mail</v-card-subtitle>
                        <v-card-text>
                            <v-form ref="form" v-model="valid" lazy-validation>
                                <v-text-field
                                    v-model="recoverPass.user.cf1cliente"
                                    :rules="cnpjRules"
                                    label="CNPJ"
                                    required
                                    dense
                                    flat
                                    solo
                                    outlined
                                    prepend-icon="mdi-account"
                                    :color="parameters.primaryColor"
                                    v-mask="'##.###.###/####-##'"
                                ></v-text-field>

                                <v-text-field
                                    v-model="recoverPass.user.cf1senha"
                                    :rules="passwordRules"
                                    label="Senha provisória"
                                    type="password"
                                    required
                                    dense
                                    flat
                                    solo
                                    outlined
                                    prepend-icon="mdi-lock-clock"
                                    :color="parameters.primaryColor"
                                ></v-text-field>
                                <v-text-field
                                    v-model="recoverPass.user.newPass"
                                    :rules="newPasswordRules"
                                    label="Nova senha"
                                    type="password"
                                    required
                                    dense
                                    flat
                                    outlined
                                    prepend-icon="mdi-lock"
                                    :color="parameters.primaryColor"
                                ></v-text-field>

                                <v-text-field
                                    v-model="recoverPass.user.confirmNewPass"
                                    :rules="newPasswordRules"
                                    label="Confirme a nova senha"
                                    type="password"
                                    required
                                    dense
                                    flat
                                    outlined
                                    prepend-icon="mdi-lock-alert"
                                    :color="parameters.primaryColor"
                                ></v-text-field>
                                <p class="subtitle-2 font-weight-bold text-left ma-0">Para criar sua senha, lembre-se que:</p>
                                <ul>
                                    <li class="caption text-left">deve possuir no mínimo 8 dígitos</li>
                                    <li class="caption text-left">deve possuir no mínimo 1 número</li>
                                    <li class="caption text-left">deve possuir no mínimo 1 letra maiúscula</li>
                                    <li class="caption text-left">deve possuir no mínimo 1 letra minúscula</li>
                                </ul>
                            </v-form>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-center align-center">
                            <v-btn
                                :disabled="!valid || recoverPass.loading"
                                :color="parameters.secondaryColor"
                                class="white--text"
                                width="80%"
                                @click="send"
                            >
                                Enviar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
export default {
    data: () => ({
        valid: true,
        cnpjRules: [
            (v) => !!v || "CNPJ é obrigatório",
            (v) => (v && v.replace(/[^\d]/g, "").length == 14) || "CNPJ inválido",
        ],
        passwordRules: [(v) => !!v || "A senha é obrigatória"],
        newPasswordRules: [
            (v) => !!v || "A senha é obrigatória",
            (v) => (v && v.length >= 8) || "No mínimo 8 dígitos",
            (v) => (v && /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/.test(v)) || "Senha não cumpre os requisitos de segurança", //(?=.*[!@#$%^&*])
        ],
    }),
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        recoverPass: {
            get() {
                return this.$store.state.recoverPass;
            },
        },
    },
    methods: {
        send() {
            if (this.$refs.form.validate()) {
                this.$store.dispatch("recoverPass/recover")
            }
        },
    },
};
</script>

<style>
</style>