<template>
    <v-list dense flat class="transparent">
        <v-list-item-title class="font-weight-bold white--text subtitle-2 pb-5"
            >INSTITUCIONAL</v-list-item-title
        >
        <v-list-item
            v-for="(item, i) in parameters.footerMenus"
            :key="i"
            @click="openRoute(item.route)"
        >
            <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </v-list>
</template>

<script>
export default {
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
    },
    methods: {
        openRoute(route) {
            this.$router.push(route);
        },
    },
};
</script>

<style>
</style>