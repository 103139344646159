<template>
    <v-card>
        <v-list>
            <v-list-item v-if="login.userLogged">
                <v-list-item-avatar>
                    <v-img
                        :src="cf1foto1"
                        alt="Avatar"
                        v-on:error="onImgError"
                    />
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title>{{ login.user.cf1nome }}</v-list-item-title>
                    <v-list-item-subtitle>{{ login.user.cf1cliente }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item link v-else @click="$router.push('/login')">
                <v-list-item-content>
                    <v-list-item-title :style="{color: parameters.primaryColor + ' !important'}">
                        Entre ou cadastre-se
                    </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                    <v-icon :color="parameters.secondaryColor">mdi-login</v-icon>
                </v-list-item-action>
            </v-list-item>
        </v-list>

        <v-divider :style="{backgroundColor: parameters.primaryColor}"></v-divider>

       <v-list dense nav flat>
            <v-list-item v-for="item in itemLeftMenu" :key="item.title" link @click="openRoute(item.route)">
                <v-list-item-icon>
                    <v-icon :color="parameters.primaryColor">{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title class="text-left" :style="{color: parameters.primaryColor}">{{ item.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item link v-if="login.userLogged" @click="logoff">
                <v-list-item-icon>
                    <v-icon :color="parameters.primaryColor">mdi-logout</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title class="text-left" :style="{color: parameters.primaryColor}">Sair</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-card>
</template>

<script>
export default {
    data: () => ({
        itemLeftMenu: [
            { title: "Minha Conta", icon: "mdi-account", route: "/minhaconta" },
            { title: "Meus Pedidos", icon: "mdi-package-variant-closed", route: "/meuspedidos" },
        ],
        failed_image: false
    }),
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        login: {
            get() {
                return this.$store.state.login;
            },
        },
        cf1foto1:{
            get(){
                return this.failed_image ? "../../../public/img/accountDefault.png" : this.login.user.cf1foto1
            }
        }
    },
    methods:{
        logoff(){
            this.$router.push("/logoff")
        },
        openRoute(route){
            this.$router.push(route)
        },
        onImgError(){
            this.failed_image = true
        }
    }
};
</script>

<style>
</style>