<template>
    <v-hover v-slot="{ hover }">
        <v-card :class="classCard" :flat="flat" :outlined="outlined" :href="`/produto/${product.pr0produto}`"><!-- @click.stop="open()" -->
            <v-img 
                id="image"
                :src="pr0imagem"
                :aspect-ratio="3/4"
                class="align-end"
                v-on:error="onImgError"
            >
                <!-- gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.3)" -->
                <v-expand-transition mode="reverse">
                    <div
                        v-if="hover"
                        class="d-flex align-center justify-center transition-fast-in-fast-out v-card--reveal white--text" 
                        :style="{ backgroundColor: parameters.secondaryColor }"
                        style="height: 100%; opacity: 0.9 !important"
                    >
                        <v-btn color="white" outlined>Ver mais</v-btn>
                    </div>
                </v-expand-transition>
            </v-img>

            <v-card-text class="pb-0 px-2" style="position: relative">
               <!--  <v-bottom-sheet v-model="product.sheet">
                    <template v-slot:activator="{ on, attrs }">
                        <v-bottom-navigation class="pa-0 v-bottom-navigation-oryx">
                            <v-btn
                                absolute
                                :color="parameters.secondaryColor"
                                class="white--text"
                                fab
                                small
                                right
                                top
                                v-bind="attrs"
                                v-on="on"
                                @click.stop="addToCart()"
                            >
                                <v-icon small>mdi-cart</v-icon>
                            </v-btn>
                        </v-bottom-navigation>
                    </template>
                    <ProductVd3List />
                </v-bottom-sheet> -->
                <div
                    class="font-weight-light grey--text subtitle-2 mb-2"
                    style="min-height: 66px"
                >
                    {{ product.pr0desc | capitalize }}
                </div>
            </v-card-text>
<!--             <v-card-actions class="pa-0 d-flex justify-center">
                <span class="grey--text caption mr-2">
                    ({{ product.pr0avalia }})
                </span>
                <v-rating
                    v-model="product.pr0avalia"
                    background-color="grey"
                    :color="parameters.secondaryColor"
                    dense
                    half-increments
                    readonly
                    size="17"
                ></v-rating>
            </v-card-actions> -->
            <v-card-actions class="d-flex justify-center">
                <div
                    v-if="login.userLogged"
                    class="font-weight-bold text-center title"
                    :style="{ color: parameters.secondaryColor }"
                >
                    {{ product.cvgpreco | money }}
                </div>
                <v-btn v-else block outlined :color="parameters.secondaryColor" @click.stop="signIn()">Ver preço</v-btn>
            </v-card-actions>
        </v-card>
    </v-hover>
</template>

<script>

export default {
    data(){
        return{
            failed_image: false
        }
    },
    props: {
        product: {
            type: Object,
            required: true,
        },
        classCard: {
            type: String,
            required: true
        },
        outlined:{
            type: Boolean,
            default: false
        },
        flat:{
            type: Boolean,
            default: false
        },
    },
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        login: {
            get() {
                return this.$store.state.login;
            },
        },
        pr0imagem:{
            get(){
                return this.failed_image ? "../../../public/img/default.jpg" : this.product.pr0imagem
            }
        }
    },
    methods: {
        open(){
            this.$router.push(`/produto/${this.product.pr0produto}`)
        },
        signIn(){
            this.$router.push("/login")
        },
        onImgError(){
            this.failed_image = true
        }
    }
};
</script>

<style scoped>
.v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .8;
    position: absolute;
    width: 100%;
  }
</style>