import { Utils } from "@/plugins/utils";

export default {
    namespaced: true,
    state: {
        salesLines: [],
        sale: {},
        selectedAddress: 0,
        showOkOrder: false,
        loading: false
    },
    getters: {
        itensOnCart(state){
            const getCartQnty = (accumulator, vd3) => accumulator + Number.parseFloat(vd3.vd3qtde)
            return state.salesLines.reduce(getCartQnty, 0)
        },
        totalCart(state){
            const getCartTotal = (accumulator, vd3) => accumulator + (vd3.vd3qtde * vd3.vd5preco)
            return state.salesLines.reduce(getCartTotal, 0)
        },
        getSale(state){
            return state.sale
        },
        getSalesLines(state){
            let salesLines = state.salesLines.map(line => {
                line.vd3qtde = parseInt(line.vd3qtde)
                return line
            })
            return salesLines
        }
    },
    mutations: {
        add(state, item){
            const filterVariant = vd3 => vd3.vd2produto == item.vd2produto && vd3.vd3opcao == item.vd3opcao && vd3.vd3tamanho == item.vd3tamanho
            let vd3InCart = state.salesLines.find(filterVariant)
            if (vd3InCart != undefined){
                vd3InCart.vd3qtde+=item.vd3qtde
            }
            else
            {
                state.salesLines.push(item)
            }
        },
        remove(state, position){
            state.salesLines.splice(position, 1)
        },
        clearCart(state){
            state.sale = {}
            state.salesLines = []
            state.selectedAddress = 0
        },
        recover(state, userCartModel){
            state.sale = userCartModel.sale
            state.salesLines = userCartModel.salesLines
        },
        initSale(state, sale){
            state.sale = sale
        },
        updateSale(state, {step = 0, vd1pedido = ""}){
            state.sale.step = step
            state.sale.vd1pedido = vd1pedido
        }
    },
    actions: {
        add(store, vd3List){
            if (Object.keys(store.state.sale).length === 0 && store.state.sale.constructor === Object) {
                store.commit("initSale", {
                    "vd1cliente": store.rootState.login.user.cf1cliente,
                    "vd1observa": "",
                    "vd1pedido": "",
                    "step": 0
                })
            }
            store.dispatch("parameters/showSnackBar",
                {
                    text: "Produto(s) adicionado(s) ao carrinho!",
                    show: true,
                    timeout: 2000
                }, {root: true})
            for (const vd3 of vd3List) {
                store.commit("add", vd3)
            }
            store.dispatch("product/closeSheet", null, {root: true})
            store.dispatch("product/clearSelection", null, {root: true})
            store.dispatch("save")
        },
        remove(store, position){
            store.commit("remove", position)
            store.dispatch("save")
        },
        clearCart(store){
            store.commit("clearCart")
        },
        async find(store){
            store.state.loading = true
            await this._vm.$axios.get(`/Cart/Cart/Find/${store.rootState.login.user.cf1cliente}`)
            .then(response => {
                let userCartModel = Utils.ValidateAxiosResponse(response.data);
                if (userCartModel) {
                    let jsonCart = JSON.parse(userCartModel.carcart)
                    store.commit("recover", jsonCart)
                    return true
                }
            })
            .catch((error) => {
                if (!(error.response && error.response.status === 401)) {
                    store.dispatch("parameters/openDialog", {
                        titleText: "Erro",
                        titleColor: "red",
                        text: error.message
                    }, { root: true })
                }
                return false
            });
            store.state.loading = false
        },
        async save(store){
            let userCartModel = { sale: {}, salesLines: []}
            userCartModel.sale = store.state.sale
            userCartModel.salesLines = store.state.salesLines

            let jsonCart = JSON.stringify(userCartModel)
            await this._vm.$axios.post("/Cart/Cart/Save",
            {
                carlogin: store.rootState.login.user.cf1cliente,
                carcart: jsonCart
            })
            .then(response => {
                return Utils.ValidateAxiosResponse(response.data);
            })
            .catch((error) => {
                if (!(error.response && error.response.status === 401)) {
                    store.dispatch("parameters/openDialog", {
                        titleText: "Erro",
                        titleColor: "red",
                        text: error.message
                    }, { root: true })
                }
                return false
            });
        },
        updateSale(store, saleModel){
            store.commit("updateSale", saleModel)
        }
    }
}